import React, { useState } from 'react';
import CryptoJS from 'crypto-js'; // Import CryptoJS for MD5 hashing

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (email && password) {
      try {
        const hashedPassword = CryptoJS.MD5(password).toString(); // Hash the password using MD5
        const response = await fetch('https://robixe-api.robixe.online/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: email, pass: hashedPassword }), // Send hashed password
        });

        console.log({ email: email, pass: hashedPassword });
        console.log(response);

        // Handle the response by status
        if (response.status === 200) {
          const data = await response.json(); // Only parse JSON if status is 200
          console.log('Login successful:', data);
          window.location.href = '/'; // Redirect after successful login
        } else if (response.status === 400) {
          throw new Error('Bad Request: Missing credentials.');
        } else if (response.status === 401) {
          throw new Error('Unauthorized: Incorrect email or password.');
        } else if (response.status === 500) {
          throw new Error('Server error. Please try again later.');
        } else {
          throw new Error('An unexpected error occurred. Please try again.');
        }
      } catch (error) {
        setError(error.message || 'An unexpected error occurred.');
      }
    } else {
      setError('Please fill in both email and password fields.');
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="flex flex-col md:flex-row w-full lg:max-w-4xl max-w-[380px] md:max-w-[750px] bg-[#2b2b2b] shadow-lg rounded-[20px] overflow-hidden relative z-20 ">
        <div className="relative flex-1 bg-indigo-500 flex justify-center items-center lg:p-6 p-14 ">
          <div className="absolute w-40 h-40 bg-white opacity-20 rounded-full top-[-50px] left-[-50px] "></div>
          <div className="absolute w-72 h-72 bg-white opacity-10 rounded-full bottom-[-100px] right-[-100px] z-0"></div>
          <div className="absolute w-36 h-36 bg-white opacity-10 rounded-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>

          <img src="/LOGO/PNG/Logo-2.png" alt="Logo" className="absolute lg:w-[33%] md:w-40 w-[24%] h-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10" />
        </div>
        
        <form className="flex-1 p-6 md:p-12 flex flex-col justify-center" onSubmit={handleSubmit}>
          <h2 className="text-3xl font-semibold text-center text-gray-100 mb-4">Welcome again</h2>
          <p className="text-gray-400 text-center mb-6">Sign in to continue your journey</p>
          
          <div className="mb-4">
            <input
              className="w-full px-4 py-3 rounded-lg bg-gray-200 text-black focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200"
              type="text"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Email"
            />
          </div>
          <div className="mb-4">
            <input
              className="w-full px-4 py-3 rounded-lg bg-gray-200 text-black focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Password"
            />
          </div>
          
          {error && <div className="bg-red-100 text-red-700 border border-red-300 p-3 rounded mb-4">{error}</div>}
          
          <button type="submit" className="w-full bg-indigo-500 hover:bg-indigo-700 text-white py-3 rounded-lg font-semibold transition duration-200">
            Sign In
          </button>
          
          <div className="mt-4 flex justify-between items-center text-sm text-indigo-500">
            <a href="#" className="hover:underline">Forgot password?</a>
            <a href="/register" className="hover:underline">Register</a>
          </div>
        </form>
      </div>
    </div>
  );
}
