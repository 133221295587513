import React, { useState } from 'react';
import MD5 from 'crypto-js/md5';

export default function Register() {
  const [user, setUser] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (user && email && password) {
      const hashedPassword = MD5(password).toString();

      // Debugging: Log the data being sent
      console.log({
      user: user,
      email: email,
      password: hashedPassword,
    });

    try {
      const response = await fetch('https://robixe-api.robixe.online/sgin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, pass: hashedPassword }),
      });

      // Check for successful registration
      if (response.status === 201) {
        console.log('Registration successful');
        window.location.href = '/login'; // Redirect to login after success
      } else {
        // Handle non-successful responses
        const errorData = await response.json();
        console.error('Error data:', errorData);
        setError('Registration failed: ' + (errorData.message || 'Unknown error'));
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Registration failed. Please try again.');
    }
  } else {
    setError('Please fill all fields.');
  }
  };


  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="flex flex-col md:flex-row w-full lg:max-w-4xl max-w-[385px] md:max-w-[750px] bg-[#2b2b2b] shadow-lg rounded-[20px] overflow-hidden ">
        <div className="relative flex-1 bg-indigo-500 flex justify-center items-center lg:p-6 p-14">
          <div className="absolute w-40 h-40 bg-white opacity-20 rounded-full top-[-50px] left-[-50px] "></div>
          <div className="absolute w-72 h-72 bg-white opacity-10 rounded-full bottom-[-100px] right-[-100px] "></div>
          <div className="absolute w-36 h-36 bg-white opacity-10 rounded-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "></div>
          <img src="/LOGO/PNG/Logo-2.png" alt="Logo" className="absolute lg:w-[33%] md:w-40 w-[24%] h-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 " />
        </div>

        <form className="flex-1 p-6 md:p-12 flex flex-col justify-center" onSubmit={handleSubmit}>
          <h2 className="text-3xl font-semibold text-center text-gray-100 mb-4">Create Account</h2>
          <p className="text-gray-400 text-center mb-6">Sign up to get started</p>

          <div className="mb-4">
            <input
              className="w-full px-4 py-3 rounded-lg bg-gray-200 text-black focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200"
              type="text"
              id="user"
              value={user}
              onChange={(e) => setUser(e.target.value)}
              required
              placeholder="Username"
            />
          </div>

          <div className="mb-4">
            <input
              className="w-full px-4 py-3 rounded-lg bg-gray-200 text-black focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200"
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Email"
            />
          </div>
          <div className="mb-4">
            <input
              className="w-full px-4 py-3 rounded-lg bg-gray-200 text-black focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Password"
            />
          </div>
          <div className="mb-4">
            <input
              className="w-full px-4 py-3 rounded-lg bg-gray-200 text-black focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200"
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              placeholder="Confirm Password"
            />
          </div>

          {error && <div className="bg-red-100 text-red-700 border border-red-300 p-3 rounded mb-4">{error}</div>}

          <button type="submit" className="w-full bg-indigo-500 hover:bg-indigo-700 text-white py-3 rounded-lg font-semibold transition duration-200">
            Sign Up
          </button>

          <div className="mt-4 flex justify-center text-sm text-indigo-500">
            <a href="/login" className="hover:underline">Already have an account? Sign in</a>
          </div>
        </form>
      </div>
    </div>
  );
}