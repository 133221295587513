import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import Register from './pages/Register'; 
import Construction from './pages/Construction';
import NotFound from './pages/NotFound';


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/robots.txt' element={"robots.txt"}/>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} /> 
          <Route path="/Construction/:itemName" element={<Construction />} />
          <Route path="*" element={<NotFound />} /> 
        </Routes>
      </Router>
    </>
  );
}

export default App;
