import React from 'react';
import './NotFound.css'; // Ensure you have the CSS for styling

const NotFound = () => {
    return (
        <div className="not-found">
            <div className="animation-container">       
                <div className="satellite"></div>
                <h1 className="error-code">404</h1>
                <h2 className="test">Oops! Page Not Found</h2>
                <p>The page you are looking for does not exist.</p>
            </div>
            <div className="navigation-links">
                <a href="/" className="home-link">Go to Home</a>
            </div>
        </div>
    );
};

export default NotFound;