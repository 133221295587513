import React from 'react';
import { useParams } from 'react-router-dom';
import AOS from 'aos';

const Construction = () => {
    const { itemName } = useParams(); 

    AOS.init({
        duration: 1200,
    });
    
    return (
        <div className="flex items-center justify-center h-screen bg-[#020013]">
            <div className="text-center" data-aos="fade-down">
                <img src="/blog.png" className="h-24 lg:-mb-2 -mb-3 lg:ml-[-90px] md:ml-[-90px] ml-[-15px] transform -rotate-[45deg]"/>
                <h1 className="text-3xl font-bold text-white mb-4">
                    {itemName?.toUpperCase()} PAGE IS UNDER CONSTRUCTION
                </h1>
                <p className="text-gray-400 text-lg">
                    This section is under development. Stay tuned for updates!<br />
                    More exciting content will be available soon!
                </p>
            </div>
        </div>
    );
};

export default Construction;
