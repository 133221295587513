import React from 'react';

// Lazily loaded components
const Nav = React.lazy(() => import('../component/1-navbar/nav'));
const Hole = React.lazy(() => import("../component/2-hole/Hole"));
const HeroContent = React.lazy(() => import('../content/HeroContent'));
const FeaturesSection = React.lazy(() => import('../component/4-FeaturesSection/FeaturesSection'));
const Ai = React.lazy(() => import('../component/5-ai/Ai'));
const FeaturesSection2 = React.lazy(() => import('../component/6-FeaturesSection2/FeaturesSection2'));
const Connected = React.lazy(() => import('../component/7-conected/Connected'));
const Information = React.lazy(() => import('../component/8-Information/Information'));
const Encryption = React.lazy(() => import('../component/9-encryption/Encreption'));
const Meetings = React.lazy(() => import('../component/10-Meetings/Meetings'));
const Team = React.lazy(() => import('../component/11-team/Team'));
const Pricing = React.lazy(() => import('../component/12-Pricing/Pricing'));
const Footer = React.lazy(() => import('../component/footer/Footer'));
const Reviews = React.lazy(() => import('../component/13.Reviews/Reviews'));


function Home() {
    return (
        <div className='w-full h-full z-10 bg-[#030014]'>
                <Nav />
                <div className='w-full h-screen mt-20'>
                    <Hole />
                    <HeroContent />
                </div>
                <FeaturesSection />
                <Ai />
                <FeaturesSection2 />
                <Connected />
                <Information />
                <Encryption />
                <Meetings />
                <Team />
                <div className="w-full h-full overflow-hidden">
                    <Pricing />
                    <Reviews />
                </div>
                <Footer />
        </div>
    );
}

export default Home;
